<template>
  <div>
    <custom-toolbar
      :title="$t('Pay_reason')"
      :total="$store.getters['payReason/total']"
      @refresh="getPayReason"
    />
    <v-card class="mb-6 mt-6">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="$store.state.payReason.field.keyword"
              label="Rechercher globalement"
              outlined
              dense
              placeholder="Rechercher globalement"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['payReason/payReasons']"
        :items-per-page="$store.getters['payReason/per_page']"
        :page.sync="$store.getters['payReason/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.color`]="{item}">
          <v-chip
            :color="item.color"
            small
          >
            {{ item.color }}
          </v-chip>
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :lock="false"
            :status="false"
            :view="false"
            :remove="false"
            @edit="openForm(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.payReason.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getPayReasonPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getPayReason"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <status-form
      :dialog="dialog"
      :object="item"
      @clickout="closeDialog"
    />
  </div>
</template>

<script>
import {
  getCurrentInstance, onMounted, ref, watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { formatDate } from '@core/date/dateFunctions'
import {
  mdiAccountLock,
  mdiDeleteOutline, mdiDeleteRestore,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus, mdiRefresh,
  mdiSquareEditOutline,
} from '@mdi/js'
import StatusForm from '@core/components/status/StatusForm'
import store from '@/store'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";
import ButtonsGroup from "@core/components/buttons/ButtonsGroup";

export default {
  name: 'PayReason',

  setup() {
    const instance = getCurrentInstance()
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const item = ref({})
    const date = ref(null)
    const message = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const menu = ref(false)
    const messageDialog = ref('')
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const dialogDelete = ref(false)
    const isAlertVisible = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const { router } = useRouter()

    const save = d => {
      menuref.value.save(d)
    }

    const getPayReason = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('payReason/getPayReasonList', { page, field: store.state.payReason.field }).then(() => {
        currentPage.value = store.getters['payReason/current_page']
        lastPage.value = store.getters['payReason/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const refreshPayReasons = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('payReason/getPayReasonList', { page, field: store.state.payReason.field }).then(() => {
        currentPage.value = store.getters['payReason/current_page']
        lastPage.value = store.getters['payReason/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    watch(() => store.state.user.lang, lang => {
      refreshPayReasons(store.getters['payReason/current_page'])
    })

    const closeDialog = () => {
      dialog.value = false
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const filterItems = () => {
      setTimeout(() => {
        getPayReason()
      }, 1500)
    }

    const getPayReasonPerPage = per_page => {
      isDialogVisible.value = true
      store.state.statusRec.field.paginate = per_page
      store.dispatch('payReason/getPayReasonList', { field: store.state.payReason.field }).then(() => {
        currentPage.value = store.getters['payReason/current_page']
        lastPage.value = store.getters['payReason/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const openForm = val => {
      if (val) item.value = val
      else {
        item.value = {
          id: null, label: null, code: null, color: null, color_object: null,
        }
      }
      dialog.value = true
    }

    const formattingDate = date => formatDate(date)

    onMounted(() => {
      getPayReason(store.getters['payReason/current_page'])
    })

    return {
      refreshPayReasons,
      dialog,
      item,
      messageDialog,
      loading,
      closeDialog,
      openForm,
      filterItems,
      getPayReasonPerPage,
      save,
      getPayReason,
      formattingDate,
      currentPage,
      message,
      lastPage,
      isAlertVisible,
      isDialogVisible,
      dialogDelete,
      error,
      date,
      menu,
      picker,
      menuref,
      headers: [
        { text: 'LABEL', value: 'label' },
        { text: 'CODE', value: 'code' },
        { text: 'COULEUR', value: 'color' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiDeleteRestore,
        mdiPlus,
      },
    }
  },
  components: {ButtonsGroup, CustomToolbar, StatusForm },
}
</script>

<style scoped>

</style>
